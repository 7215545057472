.header {
  height: 86px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  width: 100%;
  background-color: #CC5500;
  //background-color: #222;
  position: fixed;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 610px) {
    //padding-bottom: 12px;
  }

  .headerSection {
    //border: 2px solid yellow;

    img {
      height: 80px;
      //filter: invert(0.211);
    }

    &:last-child {
      display: flex;
      justify-content: space-between;
    }

    a {
      margin-right: 12px;
    }
  }
}

//.headerSection {
//    padding: 20px;
//    display: flex;
//
//    border: 2px solid yellow;
//}

