.info {
  width: 100%;
  height: 100%;
  padding: 32px;
  box-sizing: border-box;
  display: grid;

  //grid-template-columns: repeat(3, 32%);

  grid-template-columns: 15% 15% 15% 15% 15% 15%;
  grid-template-rows: auto;
  grid-template-areas:
          'chart chart deals deals plans plans'
          'chart chart income income report link';

  gap: 30px;

  @media screen and (max-width: 1192px) {
    grid-template-areas: none;
    overflow-y: scroll;
    grid-template-columns: repeat(2, 49%);

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #666;
      border-radius: 4px;
    }
  }

  @media screen and (max-width: 950px) {
    overflow-y: scroll;
    grid-template-columns: repeat(1, auto);

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #666;
      border-radius: 4px;
    }
  }
}
