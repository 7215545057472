@import-normalize;
@import 'src/global/styles/_variables.scss';

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100%;
  background-color: $primary-colour-dark;
  scroll-behavior: smooth;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #666;
    border-radius: 4px;
  }
  //background:
  //        conic-gradient(at 50% calc(100%/6),rgba(204, 85, 0, 0.36) 60deg,#0000 0),
  //        conic-gradient(at calc(100%/6) 50%,#0000 240deg,rgba(204, 85, 0, 0.36) 0),
  //        conic-gradient(from 180deg at calc(100%/6) calc(500%/6),rgba(204, 85, 0, 0.36) 60deg,#0000 0),
  //        conic-gradient(from 180deg at calc(500%/6),#0000 240deg,rgba(204, 85, 0, 0.36) 0) calc(4*.866*50px) 0,
  //        repeating-linear-gradient(-150deg,rgba(255,136,51,0.36) 0 calc(100%/6),#0000   0 50%),
  //        repeating-linear-gradient(-30deg, rgba(255,196,153,0.36) 0 calc(100%/6),rgba(255, 255, 255, 0) 0 50%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


