@import "src/global/styles/variables";

.skeleton {
  border-radius: 24px;
  box-sizing: border-box;
  padding: 20px;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
  //border: 2px solid white;
  display: flex;
  height: 100px;


  .left {
    flex: 1;
  }

  .center {
    flex: 3;
    display: flex;
    box-sizing: border-box;
    justify-content: space-evenly;
    padding-right: 8px;
    padding-left: 8px;
  }

  .right {
    flex: 1;
  }

  &.light {
    background-color: #ddd;
  }

  &.dark {
    background-color: $secondary-colour-dark;
  }
}
