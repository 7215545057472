.section {
    //background-color: #CC5500;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    //flex: 1;

    //border: 3px solid white;
    //position: relative;

    @media screen and (max-width: 962px) {
        //padding: 60px 0;
        //height: calc(100vh - 86px);
        //height: auto;
    }
}

.sectionHeader {
    //border: 1px solid white;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sectionHeader h2 {
    font-weight: 900;
    color: white;
    font-size: 44px;

}

.plans {
    //border: 1px solid white;


    display: flex;
    //flex: 1;
    width: 100%;
    height: 80%;
    justify-content: center;
    margin-bottom: 40px;

    @media screen and (max-width: 962px){
        flex-direction: column;
        //padding: 30px;
        align-items: center;
    }

    //border: 2px solid white;
}

.sectionBottom {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.sectionBottom img {
    width: 70%;
    margin-bottom: 30px;
}
