.card {
  width: 24%;
  //width: 260px;
  //height: 400px;
  height: 100%;
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: white;
  transition: .3s;
  //cursor: pointer;
  display: flex;
  flex-direction: column;
  z-index: 9;
  margin-bottom: 6px;
  margin-right: 2%;
  color: black;

  .description {
    color: #222;
    position: relative;
    padding: 20px;
    display: flex;
    flex-direction: column;
    font-size: 18px;

    span {
      position: absolute;
      top: 14px;
      right: 14px;
      font-size: 44px;
      cursor: pointer;
    }

    h3 {
      margin: 0;
      font-size: 24px;
    }
  }

  &.pro {
    color: white !important;
    transition: 0.3s;
    //background: linear-gradient(180deg, #121562 0%, #5D6694 100%);
    background: linear-gradient(160deg, #121562, #5d6694, #c50);
    background-size: 400% 400%;
    -webkit-animation: ProGradient 8s ease infinite;
    -moz-animation: ProGradient 8s ease infinite;
    -o-animation: ProGradient 8s ease infinite;
    animation: ProGradient 8s ease infinite;

    .info .top span {
      color: #999999;
    }

    .info .bottom span {
      color: white;
    }

    .description {
      color: white;
    }

    @-webkit-keyframes ProGradient {
      0% {
        background-position: 0 24%
      }
      50% {
        background-position: 100% 77%
      }
      100% {
        background-position: 0 24%
      }
    }
    @-moz-keyframes ProGradient {
      0% {
        background-position: 0 24%
      }
      50% {
        background-position: 100% 77%
      }
      100% {
        background-position: 0 24%
      }
    }
    @-o-keyframes ProGradient {
      0% {
        background-position: 0 24%
      }
      50% {
        background-position: 100% 77%
      }
      100% {
        background-position: 0 24%
      }
    }
    @keyframes ProGradient {
      0% {
        background-position: 0 24%
      }
      50% {
        background-position: 100% 77%
      }
      100% {
        background-position: 0 24%
      }
    }
  }

  .plan {
    height: 50%;
    border-radius: 24px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.start {
      background-color: #679AE7;
    }

    &.advanced {
      background-color: #E4D00A;
    }

    &.pro {
      background-color: black;
    }
  }

  .info {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    //border: 2px solid black;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    flex: 1;

    .top {
      display: flex;
      flex-direction: column;
      text-align: start;
      padding-top: 18px;
      //padding-left: 14px;
      //border: 1px solid black;

      h3 {
        margin-top: 0;
        margin-bottom: 6px;
        font-size: 36px;
      }

      span {
        font-size: 24px;
        font-weight: 600;
        color: #333;
      }
    }

    //
    .bottom {
      //border: 1px solid black;
      display: flex;
      justify-content: space-between;
      //padding-right: 20px;
      //padding-bottom: 20px;
      //padding-left: 20px;
      height: 16%;
      align-items: center;
      //position: relative;

      span {
        color: #222;
        font-size: 16px;
        cursor: pointer;
        text-decoration: underline dotted;
        text-underline-offset: 3px;
        //position: absolute;
      }

      button {
        background-color: #CC5500;
        color: white;
        font-size: 16px;
        width: 30%;
        text-align: center;
        border-radius: 16px;
        border: none;
        outline: none;
        padding: 8px;
        cursor: pointer;
        transition: .3s;
        //z-index: 999;
      }
    }
  }

  &:hover {
    scale: 1.06;

    .info .bottom button {
      //width: 34%;
      //padding: 12px;
      scale: 1.1;
      //border-radius: 20px;
      //font-weight: 600;
      //font-size: 14px;
    }

    //&.pro {
    //  background: linear-gradient(0deg, rgba(149,119,36,1) 0%, rgba(0,173,197,1) 0%, rgba(112,231,55,1) 100%);
    //}

    //border: ;
  }

  img {
    width: 50%;
    height: 50%;
  }

  @media screen and (max-width: 1152px) {
    width: 200px;
  }

  @media screen and (max-width: 962px) {
    flex-direction: row;
    height: 32%;
    width: 90%;
    margin-bottom: 12px;

    .plan {
      width: 25%;
      height: 100%;
    }

    .description {
      flex: 1;
    }
  }
}

