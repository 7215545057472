@import "src/global/styles/variables";

.header {
  height: 110px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-left: 32px;
  padding-right: 32px;
  justify-content: space-between;
  position: relative;
  //border: 1px solid white;

  h1 {
    font-weight: 400;
    margin: 0;
  }

  .buttons {
    display: flex;

    button, a {
      height: 48px;
      border-radius: 16px;
      margin-left: 16px;
      outline: none;
      cursor: pointer;
      background-color: transparent;
      border: 2px solid $secondary-colour-dark;
      width: 48px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      background-position: center center;
      background-size: 24px 24px;
      background-repeat: no-repeat;

      &:nth-child(2) {
        padding-left: 12px;
        padding-right: 12px;
        background-color: $secondary-colour-light;
        border: none;
        width: auto;
      }
    }

    .loading {
      background-image: url(../../../../../assets/images/svg/loading.svg);
    }

    &.dark {
      a {
        background-image: url(../../../../../assets/images/svg/dark/telegram.svg);
      }

      .off {
        background-image: url(../../../../../assets/images/svg/dark/notification.svg);
      }

      .on {
        background-image: url(../../../../../assets/images/svg/dark/notification_off.svg);
      }
    }


    &.light {
      a {
        background-image: url(../../../../../assets/images/svg/light/telegram.svg);
      }

      .off {
        background-image: url(../../../../../assets/images/svg/light/notification.svg);
      }

      .on {
        background-image: url(../../../../../assets/images/svg/light/notification_off.svg);
      }
    }
  }

  //flex: 1;
}
