@import 'src/global/styles/variables';

.option {
  //border: 1px solid yellow;
  padding: 12px 18px;
  border-radius: 12px;
  margin-bottom: 12px;
  appearance: none;
  color: white;
  outline: none;
  //border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .toggle {
    //display: flex;
    //align-items: center;
    //height: 100%;

    &.unchecked {
      justify-content: flex-end;
      //padding-right: 6px;
    }

    &.checked {
      justify-content: flex-start;
      //padding-left: 6px;
    }
  }

  &.select {
    background-repeat: no-repeat;
    background-position: right 18px center;
    background-size: 12px;
  }

  &:has(.copy) {
    //border: 1px solid white;
    //text-decoration: underline;
    justify-content: flex-start;
  }

  &.light {
    color: #222;
    //background-color: $secondary-colour-light;
    background-color: #dddddd;
    background-image: url('../../../../../assets/images/svg/light/arrow-down.svg');

    &.support {
      background-color: $primary-colour-light;
    }

    span a {
      color: #222;
    }

    .copy {
      background-image: url(../../../../../assets/images/svg/light/copy.svg);

      &:hover {
        color: #999999;
        background-color: #ddd;
      }
    }
  }

  &.dark {
    background-color: $secondary-colour-dark;
    background-image: url('../../../../../assets/images/svg/dark/arrow_down.svg');

    &.support {
      background-color: $primary-colour-dark;
    }

    span a {
      color: white;
    }

    .copy {
      background-image: url(../../../../../assets/images/svg/dark/copy.svg);

      &:hover {
        color: #999999;
        background-color: $secondary-colour-dark;
      }
    }
  }

  .copy {
    //background-color: $secondary-colour-dark;
    border: 1px solid #999999;
    color: #999999;
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center right 12px;
    padding-left: 12px;
    //width: 120px;
    transition: none;
    border-radius: 12px;
    margin-left: 24px;

    span {
      color: #999999;
      margin-right: 20px;
      //text-decoration: none !important;
    }
  }
}
