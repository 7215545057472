@import 'src/global/styles/variables';

.card {
  border-radius: 16px;
  //background-color: $primary-colour-light;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //border: 1px solid white;
  flex-direction: column;
  padding: 20px;

  &.dark {
    background-color: $secondary-colour-dark;

    a {
      color: white;
    }

    .header {
      border-bottom: 2px solid #999999;

      .mainInfo .info .item {
        &.category p {
          color: #999999;
        }

        &.date span {
          color: #999999;
        }
      }

      .actions {
        button {
          border: 1px solid white;

          &:hover {
            border: 1px solid #999999;
            background-color: #999999;
          }
        }
      }
    }

    .info {
      .main .item p {
        color: #999999;
      }
    }
  }

  &.light {
    background-color: #ddd;

    a {
      color: #222;
    }

    .header {
      border-bottom: 2px solid $secondary-colour-light;

      .mainInfo .info .item {
        &.category p {
          color: #222;
        }

        &.date span {
          color: green;
        }
      }

      .actions {
        button {
          border: 1px solid black;

          &:hover {
            border: 1px solid $secondary-colour-light;
            background-color: $secondary-colour-light;
          }
        }
      }
    }

    .info {
      .main .item p {
        color: #999999;
      }
    }
  }

  .header {
    display: flex;
    //border: 1px solid green;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 20px;

    .mainInfo {
      display: flex;
      //align-items: center;

      .image {
        //border: 1px solid yellow;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          //width: 80px;
          height: 80px;
          border-radius: 16px;
        }
      }

      .info {
        //border: 1px solid black;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .item {
          //border: 1px solid pink;
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-left: 8px;

          p {
            margin: 0;
          }

          &.itemName p {
            font-weight: 600;
            font-size: 16px;
          }


          &.category p {
            font-size: 13px;
          }

          &.date {
            p {
              font-weight: 600;
              font-size: 13px;
            }
          }

          &.status span {
            text-align: center;
            border-radius: 8px;
            margin: 0;
            color: white;
            background-color: $secondary-colour-light;
            font-size: 14px;
            padding: 2px 12px;
          }
        }
      }
    }

    .actions {
      display: flex;
      justify-content: space-between;

      button {
        width: 44px;
        height: 44px;
        margin-right: 4px;
        cursor: pointer;
        background-color: transparent;
        display: flex;
        border-radius: 8px;
        justify-content: center;
        align-items: center;
        padding: 8px;
        transition: .2s;

        img {
          width: 20px;
        }

        &:last-child {
          img {
            width: 34px;
          }
        }
      }
    }
  }

  .info {
    //border: 2px solid blue;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;

    .main {
      flex: 1;
      //border: 2px solid cyan;
      display: grid;
      grid-template-columns: repeat(4, auto);
      gap: 20px;
      padding: 18px;

      .item {
        //border: 1px solid navajowhite;
        display: flex;
        flex-direction: column;
        padding: 6px;

        h5 {
          font-size: 16px;
          margin: 0 0 4px;
        }

        p {
          margin: 0;
          font-size: 14px;
        }
      }

      @media screen and (max-width: 774px) {
        grid-template-columns: repeat(3, auto);
      }
    }

    .restriction {
      width: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      //border: 1px solid white;
      //flex: 1;

      img {
        width: 80%;
        margin-bottom: 12px;
      }

      button {
        border-radius: 8px;
        border: none;
        cursor: pointer;
        background-color: green;
        color: white;
        padding: 4px;
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    button {
      color: white;
      border: none;
      display: flex;
      padding: 4px 12px;
      cursor: pointer;
      border-radius: 8px;
      font-weight: 600;
      align-items: center;
      box-sizing: border-box;
      height: 30px;

      img {
        height: 20px;
        margin-right: 6px;
      }
    }

    .left button {
      background-color: #999999;
    }

    //
    .right {
      display: flex;

      a {
        text-decoration: none;

        &:first-child {
          button {
            background-color: deepskyblue;
            margin-right: 8px;
          }
        }

        &:last-child {
          button {
            background-color: #f90;
          }
        }
      }
    }
  }
}
