@import "src/global/styles/variables";

.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;

  &.show {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content {
    background-color: $secondary-colour-dark;
    position: fixed;
    width: 80%;
    height: 70%;
    border-radius: 16px;
    display: flex;
    //overflow: hidden;
    //top:50%;
    //left:50%;
    //transform: translate(-50%,-50%);

    span {
      position: absolute;
      right: 12px;
      top: 12px;
      font-size: 34px;
      color: white;
      cursor: pointer;
    }

    .left {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 16px;

      &.start {
        background-color: #679AE7;
      }

      &.advanced {
        background-color: #E4D00A;
      }

      &.pro {
        background-color: black;
      }

      img {
        width: 70%;
        height: 70%;
      }
    }

    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      //position: relative;

      .top {
        width: 100%;
        box-sizing: border-box;
        height: 70px;
        display: flex;
        padding-left: 82px;
        flex-direction: column;
        padding-top: 32px;

        h1 {
          color: white;
          margin: 0 0 12px;
          font-size: 54px;
        }

        h2 {
          margin: 0;
          color: #ddd;
          font-size: 40px;
        }
      }

      .description {
        width: 70%;
        box-sizing: border-box;
        //flex: 1;
        //flex-grow: 1;
        //border: 1px solid white;
        //display: flex;
        //flex-direction: column;
        //justify-content: center;
        position: relative;

        p {
          //display: inline-block;
          //text-indent: 5%;
          color: #888;
          //margin: 0 0 32px;
          font-size: 20px;
          text-align: left;
        }
      }

      .bottom {
        box-sizing: border-box;
        //padding: 22px;
        width: 70%;
        //border: 1px solid white;
        //position: absolute;
        //margin: -70px;
        //margin-top: -50%;

        &.open {
          width: 40%;
          //background-color: $secondary-colour-dark;
          backdrop-filter: blur(22px);
          transform: translateY(-15%);
          position: absolute;
        }
      }
    }
  }
}
