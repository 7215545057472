.section {
    color: white;
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: center;
    //border: 1px solid white;
    width: 100%;
    height: calc(100vh - 86px);
    //padding: 12px 0;
    box-sizing: border-box;
}

.sectionContent {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.sectionContentTitle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.sectionContentTitle h1{
    font-weight: 900;
    font-size: 48px;
}

.sectionContentDesc {
    width: 100%;
    display: flex;
    height: 70%;
    //align-items: center;
    //justify-content: center;
    background-color: #CC5500;
    padding: 14px 32px;
    border-radius: 24px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 50px;
}

.sectionContentDesc p {
    font-weight: 600;
    font-size: 28px;
    color: white;
    text-align: start;
}
