.page {
    display: flex;
    background-color: #242529;
    height: 100vh;
    justify-content: space-evenly;
    align-items: center;
}

//.page img {
//    filter: invert(100%);
//}
