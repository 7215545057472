@import "src/global/styles/variables";


.skeleton {
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 12px;

  .header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 20px;

    .mainInfo {
      display: flex;
      align-items: center;
    }
  }

  .info {
    height: 264px;
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &.light {
  background-color: #ddd;
  }

  &.dark {
    background-color: $secondary-colour-dark;
  }
}
