.deals {

  width: 100%;
  height: 100%;
  //background-color: white;
  display: flex;
  flex-direction: column;
  color: #fff;

  &.light {
    background-image: linear-gradient(to left top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #9d95df, #a08fdc, #a489d8, #be6cbc, #d04b93, #d42a60, #c71928);
  }

  &.dark {
    background-image: linear-gradient(to left top, #5f16a3, #a4006f, #b5003d, #aa4116, #916600, #876d00, #7c7300, #6f7900, #7e6a00, #8b5900, #974400, #9f2900);
  }

  .top {
    flex: 1;
    display: flex;
    padding: 32px;
    box-sizing: border-box;

    h2 {
      margin: 0;
      font-size: 44px;
    }
  }

  .bottom {
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 32px;
    padding-left: 32px;
    padding-right: 32px;
    box-sizing: border-box;

    button {
      width: 100%;
      border-radius: 16px;
      border: none;
      outline: none;
      height: 52px;
      cursor: pointer;
    }
  }
}
