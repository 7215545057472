@import "src/global/styles/variables";

.profile {
  padding-bottom: 22px;
  padding-top: 22px;

  &.dark {
    border-bottom: 1px solid $secondary-colour-dark;
    background-color: #242529;
    opacity: 1;
    background-image:  radial-gradient(#36383E 1.25px, transparent 1.25px), radial-gradient(#36383E 1.25px, #242529 1.25px);
    background-size: 50px 50px;
    background-position: 0 0,25px 25px;
  }

  &.light {
    border-bottom: 1px solid #999999;
    background-color: #fff;
    opacity: 1;
    background-image:  radial-gradient(#c50 1.25px, transparent 1.25px), radial-gradient(#c50 1.25px, #fff 1.25px);
    background-size: 50px 50px;
    background-position: 0 0,25px 25px;
  }

  .change {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    //border: 1px solid yellow;

    .image {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      margin-right: 40px;
      //align-items: center;
      //border: 1px solid white;

      .input {
        //width: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        img {
          width: 140px;
          height: 140px;
          margin-bottom: 12px;
          border-radius: 50%;
          object-fit: cover;
          border: 1px solid $secondary-colour-light;
        }

        input[type='file'] {
          //border: 1px solid white;
          width: 120px;
          //display: flex;
          //justify-content: center;
          //align-items: center;

          &::-webkit-file-upload-button {
            visibility: hidden;
          }

          &::before {
            width: 76%;
            content: 'Select';
            padding: 8px 12px;
            border-radius: 12px;
            display: inline-block;
            text-align: center;
            cursor: pointer;
            border: 2px solid $secondary-colour-light;
            background-color: transparent;
            -webkit-user-select: none;
            color: #999999;
            font-weight: 600;
            outline: none;
            white-space: nowrap;
          }
        }
      }
    }

    .login {
      .name {
        display: flex;
        //border: 1px solid white;
        background-position-x: center;
        align-items: center;
        justify-content: space-between;
        font-size: 32px;
        padding: 4px 20px;
        border-radius: 12px;
        cursor: pointer;
        margin-bottom: 6px;

        img {
          display: none;
        }

        h3 {
          margin: 0;
        }

        &:hover {
          img {
            height: 26px;
            width: 26px;
            display: block;
          }
        }

        &.dark {
          background-color: $secondary-colour-dark;
        }

        &.light {
          background-color: #ddd;
        }
      }

      .input {
        //border: 1px solid white;
        display: flex;
        height: 40px;

        button {
          border: none;
          padding: 4px 12px;
          color: white;
          background-color: $secondary-colour-light;
          border-radius: 0 12px 12px 0;
          height: 100%;

          &.disabled { background-color: #999999}
        }

        input[type='text'] {
          outline: none;
          background-color: transparent;
          padding: 8px 12px;
          border-radius: 12px 0 0 12px;
          border: 2px solid $secondary-colour-light;
          width: 240px;
          //flex: 1;
          height: 100%;
          box-sizing: border-box;

          &.light {
            color: #222;
          }

          &.dark {
            color: white;
          }
        }
      }
    }

    h4 {
      margin: 0 0 12px;
    }
  }
}
