.chart {
  //border: 1px solid white;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  //background: linear-gradient(68.8deg, rgba(0, 0, 0, 0.95) 1.8%, rgb(0, 55, 79) 31.8%, rgb(9, 93, 134) 56.2%, rgb(15, 155, 217) 89%);

  .top {
    //border: 1px solid black;
    width: 100%;
    padding: 32px 32px 0;
    box-sizing: border-box;

    h3 {
      margin: 0;
    }
  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    //text-align: center;
    flex: 1;
  }

  .donut {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    //align-items: flex-start;
    //border: 1px solid red;
    //padding-right: 4px;
    flex: 1;
    padding-right: 22px;


    .cats {
      flex: 1;
      padding-right: 22px;

      .list {
        .category {
          border-bottom: 1px solid #666;
          display: flex;
          font-size: 14px;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          padding-bottom: 4px;
          margin-bottom: 6px;

          &:last-child {
            border: none;
          }

          p {
            margin: 0;
          }

          .left {
            display: flex;
            align-items: center;

            span {
              background-color: black;
              width: 12px;
              height: 12px;
              border-radius: 4px;
              margin-right: 6px;
            }

            p {
              margin: 0;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1193px) {
    .top h3 {
      font-size: 34px;
    }

    .donut {
      flex-direction: column;
      //border: 2px solid red;
      padding: 0 20px;

      .cats {
        flex: unset;
        height: 40%;
        //border: 2px solid blue;
        width: 100%;
        padding: 20px;

        .list .category {
          font-size: 20px;
        }
      }
    }
  }

  &.light {
    background-color: #FFF379;
    color: #222;
  }

  &.dark {
    //background-color: #0E2954;
    //background-color: #0E2954;
    color: white;
  }
}
