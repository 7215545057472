@import "src/global/styles/variables";

.section {
  flex: 4;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $secondary-colour-dark;

  @media screen and (max-width: 1192px) {
    flex: 1;
  }
}
