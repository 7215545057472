@import "src/global/styles/variables";


.plans {

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  transition: .3s;

  &.light {
    background-color: #ccc;

    .header h2 {
      color: black;
    }

    .info .plan{
      color: #222;
    }
  }

  &.dark {
    .header h2 {
      color: white;
    }
  }

  .header {
    flex: 1;
    align-items: center;
    padding-top: 32px;
    padding-left: 32px;
    //color: white;


    h2 {
      margin: 0;
    }
  }

  .info {
    flex: 4;
    display: flex;
    flex-direction: column;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 32px;

    .plan {
      display: flex;
      box-sizing: border-box;
      align-items: center;
      font-size: 24px;
      //flex-wrap: wrap;
      justify-content: space-between;
      color: white;


      span {
        font-weight: 600;

        &:first-child {
          margin-left: 20px;
          margin-right: 20px;
          padding: 6px 18px;
          border-radius: 12px;
          //border: 1px solid darkblue;
        }

        &:last-child {
          color: green;
          //text-decoration: underline dotted;
          margin-left: 20px;
          padding: 6px 18px;
          border-radius: 12px;
          //background-color: green;
        }
      }

      &.starter {
        span:first-child {
          background-color: #679AE7;
          color: #222;
        }
      }

      &.advanced {
        span:first-child {
          background-color: #E4D00A;
          color: black;
        }
      }

      &.pro {
        span:first-child {
          background-color: black;
          color: white;
        }
      }

      @media screen and (max-width: 1078px) {
        font-size: 18px;
      }
    }

    .empty {
      display: flex;
      align-items: center;
      justify-content: center;
      //border: 1px solid white;
      font-size: 24px;
      flex: 1;
    }
  }
}
