.form {
  display: flex;
  flex-direction: column;
  background-color: #36383E;
  //width: 60%;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 24px;
  width: 100%;
  align-items: center;

  //border: 2px solid white;
}

.header {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  img {
    filter: invert(1);
    width: 60%;
    height: auto;
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 70%;
  //border: 1px solid white;

  h3 {
    color: white;
  }

  .input {
    height: 60px;
    background-color: #242529;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border: none;
    outline: none;
    margin-bottom: 20px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-right: 22px;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
      //border: 1px solid green;
      -webkit-text-fill-color: white;
      -webkit-box-shadow: 0 0 0 1000px transparent inset;
      transition: background-color 5000s ease-in-out 0s;
    }

    img {
      height: 24px;
    }

    input {
      height: 80%;
      width: 86%;
      background-color: transparent;
      border: none;
      outline: none;
      color: white;
      font-weight: 600;
      font-size: 22px;


      &::placeholder {
        color: #36383E;
      }
    }
  }

  .bottom {
    display: flex;
    //justify-content: space-between;
    //align-items: center;
    flex-direction: column;

    .text {
      font-size: 14px;
      color: white;
      //font-weight: 600;
      padding-left: 12px;
      display: flex;
      justify-content: center;

      &.top {
        justify-content: center;
        margin-bottom: 12px;

        .remember {
          display: flex;
          align-items: center;

          input[type='checkbox'] {
            cursor: pointer;
          }

          span {
            color: white;
            text-decoration: none;
          }
        }
      }
    }

    p {
      font-size: 14px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    span {
      color: #14FF00;
      text-decoration: underline;
      cursor: pointer;
    }

    button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      //width: 140px;
      padding: 4px 18px;
      border-radius: 20px;
      height: 60px;
      cursor: pointer;
      border: none;
      outline: none;
      background-color: #CC5500;
      color: white;
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 20px;
    }
  }
}
