.history {
  //border: 1px solid white;
  width: 90%;
  display: flex;
  flex-direction: column;

  .header {
    padding-top: 32px;
    //border: 1px solid white;
    margin-bottom: 20px;


    h1 {
      margin: 0;
    }
  }

  .deals {
    flex: 1;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #666;
      border-radius: 4px;
    }
  }
}
