.empty {
  //border: 1px solid white;
  //height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    color: #999999;
    font-size: 44px;
  }
}
