.option {
    border-radius: 12px;
    transition: .3s;
    margin-bottom: 8px;
    padding: 12px 12px;
    color: #666;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.option img {
    height: 20px;
    margin-right: 8px;
}

.option.active {
    color: white;
    font-size: 15px;
}

.option.light.active {
    background-color: #c50;
}

.option.dark.active {
    background-color: #36383E;
}

.option.dark:hover {
    color: white;
}

/*.option.light:hover {*/
/*    color: black;*/
/*}*/
