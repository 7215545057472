.page {
    display: flex;
    height: 100vh;
    transition: .6s;
}

.dark {
    background-color: #242529;
}

.light {
    background-color: white;
}
