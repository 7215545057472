.header {
    width: 100%;
    /*border: 1px solid yellow;*/
    margin-bottom: 30px;
}

.header img {
    width: 90%;
}

.header.dark img {
    filter: invert(100%);
}
