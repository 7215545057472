@import "src/global/styles/variables";

.settings {
  //border: 1px solid white;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //background-color: #999999;

  .options {
    padding-bottom: 22px;

    &.dark {
      border-bottom: 1px solid $secondary-colour-dark;
    }

    &.light {
      border-bottom: 1px solid #999999;
    }

    h1, h2 {
      margin: 24px 0 12px;
    }

    //.rotate {
    //  display: flex;
    //
    //  h2 {
    //    margin: 0;
    //    font-size: 64px;
    //    transform: rotate(-90deg);
    //  }
    //}

    display: flex;
    flex-direction: column;
  }

  button {
    background-color: transparent;
    border: 2px solid red;
    outline: none;
    transition: .3s ease-in;
    padding: 12px 16px;
    border-radius: 16px;
    color: red;
    font-weight: 600;
    cursor: pointer;
    align-self: flex-end;

    &:hover {
      background-color: red;
      color: #222;
    }
  }
}
