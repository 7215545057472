@import "src/global/styles/variables";

.deals {
  width: 90%;
  //width: 70vw;
  //height: 90vh;
  //height: auto;
  flex: 1;
  padding: 24px 20px;
  box-sizing: border-box;
  font-size: 8px;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 38px;
  }

  .items {
    //width: 100%;
    overflow-y: scroll;
    //width: 70vw;
    //border: 2px solid red;
    //flex: 1;
    //height: 80%;
    box-sizing: border-box;
    //padding-top: 20px;
    //padding-bottom: 20px;

    &::-webkit-scrollbar {
      display: none;
    }

    &.flex {
      display: flex;
      flex-direction: column;
      //border: 2px solid red;
    }

    &.grid {
      display: grid;
      grid-template-columns: repeat(2, auto);
      gap: 10px;

      @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(1, auto);
      }

      //@media screen and (max-width: 840px) {
      //  grid-template-columns: repeat(1, auto);
      //}
    }
  }

  @media screen and (max-width: 960px) {
    .options {
      .actual {
        //flex-direction: column;
        .option {
          p {
            font-size: 14px;
          }
        }
      }

      .sort {
        flex: 2;
        flex-direction: column;
      }
    }
  }
}
