.authBtn {
    width: 140px;
    height: 40px;
    border-radius: 14px;
    //background-color: white;
    border: 2px solid #222;
    //color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 600;
    text-decoration: none;
    color: #222222;
    transition: .2s;

    &:hover {
        color: white;
        background-color: #222;
    }
}

.authBtnSection {
    display: flex;
    justify-content: center;
    align-items: center;
}

.authBtn .authBtnSection img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}
