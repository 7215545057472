.section {
  background: linear-gradient(120deg, #cc5500, #7d58cc);
  background-size: 400% 400%;

  -webkit-animation: background-animation 5s ease infinite;
  -moz-animation: background-animation 5s ease infinite;
  animation: background-animation 5s ease infinite;
  //background: rgba(204, 85, 0, 0.36);
  //height: 540px;
  height: calc(100vh - 86px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 86px;
}

@-webkit-keyframes background-animation {
  0% {
    background-position: 32% 0%
  }
  50% {
    background-position: 69% 100%
  }
  100% {
    background-position: 32% 0%
  }
}

@-moz-keyframes background-animation {
  0% {
    background-position: 32% 0%
  }
  50% {
    background-position: 69% 100%
  }
  100% {
    background-position: 32% 0%
  }
}

@keyframes background-animation {
  0% {
    background-position: 32% 0%
  }
  50% {
    background-position: 69% 100%
  }
  100% {
    background-position: 32% 0%
  }
}

.sectionContent {
  width: 80%;
  height: 34%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.sectionContentText {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: start;
}

.sectionContentText h1 {
  //color: white;
  font-weight: 900;
  font-size: 56px;
}

.sectionContentBtn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.sectionContentBtn button {
  border-radius: 18px;
  //height: 48px;
  //width: 250px;
  border: none;
  padding: 8px 16px;
  outline: none;
  cursor: pointer;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background-color: #CC5500;
}

.sectionContentBtn span {
  font-weight: 600;
  font-size: 28px;
  color: white;
}
