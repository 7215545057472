.profile {
    /*border: 1px solid white;*/
    display: flex;
    flex-direction: column;
    /*justify-content: space-between;*/
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;

    margin-bottom: 30px;
}

.profile img {
    //height: 60px;
    //width: 60px;
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin-bottom: 12px;
    border-radius: 50%;
    border: 1px solid #CC5500;
    //filter: invert(.3);
}

.profile h5 {
    margin: 0;
    font-weight: 600;
}

.profile.light h5 {
    color: #222;
}

.profile.dark h5 {
    color: white;
}
