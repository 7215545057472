.link {

  width: 100%;
  height: 100%;
  display: flex;
  background-position: 50% 50%;
  background-size: 50% 50%;
  background-repeat: no-repeat;

  &.light {
    background-color: lightgreen;
    background-image: url(../../../../../../../assets/images/svg/light/telegram.svg);
  }

  &.dark {
    background-color: black;
    background-image: url(../../../../../../../assets/images/svg/dark/telegram.svg);
  }
}
