.main {
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;
    //width: 100%;
    box-sizing: border-box;
    //scroll-behavior: smooth;
    //border: 2px solid white;
}
