.sectionLine {
    width: 86%;
    margin-bottom: 30px;
    margin-top: 30px;
    /*display: flex;*/
    /*align-items: center;*/
    /*position: relative;*/
    /*width: 800px;*/
}

.sectionLineHorizont {
    /*width: 10%;*/
    height: 8px;
    border-radius: 8px;
    background-color: #CC5500;
    display: flex;
    align-items: center;
    position: relative;

}

.sectionLineStart {
    width: 24px;
    height: 24px;
    background-color: #CC5500;
    border-radius: 50%;
    position: absolute;
    right: -2px;
}

.sectionLineEnd {
    width: 24px;
    height: 24px;
    background-color: #CC5500;
    border-radius: 50%;
    position: absolute;
    left: -2px;
}
